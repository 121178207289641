import { TemplateRef } from '@angular/core';
import { SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';

export enum ConfirmationDialogButton {
  Ok = 1,
  Cancel = 2,
  Auto = 3,
  Aux1 = 4
}

export class ConfirmationDialogResponse {
  buttonType: ConfirmationDialogButton;
  id: string;
  parentData: any;
  dialogData: any;
  noUiBlock: any;
}
export class ConfirmationDialogButtonProps {
  name?: string;
  class?: string;
  subtext?: string;
}
export class DialogRequest {
  id?: string;
  parentData?: any;
  config?: any;
  noUiBlock?: boolean;
  component?: TemplateRef<any> | string | any;
}
export class ConfirmationDialogRequest extends DialogRequest {
  title?: string;
  content?: string;
  buttons?: ConfirmationDialogButton;
  modalClass?: string;
  okBtnName?: string;
  okBtnClass?: string;
  okBtnSubtext?: string;
  cancelBtnName?: string;
  cancelBtnClass?: string;
  cancelBtnSubtext?: string;
  aux1BtnName?: string;
  aux1BtnClass?: string;
  aux1BtnSubtext?: string;
}
export class CloseDialogRequest {
  ids: SafariObjectId[];
}
