import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from '@safarilaw-webapp/shared/common-objects-models';
import { of, mergeMap, throwError, Observable } from 'rxjs';

export class CrudTestError {
  name: string;
  endpoint: string;
  method: { id: HttpMethod; name: string }[];
  failureRate: number;
  errorCode: number;
  canDelete: boolean;
}
export class CrudTestErrorService {
  static getTestError<T>(endpoint: string, method: string): Observable<T> {
    const endpointFailuresAsString = localStorage.getItem('endpointFailures');
    if (!endpointFailuresAsString) {
      return null;
    }
    const endpointFailures = JSON.parse(endpointFailuresAsString) as CrudTestError[];

    const postfailures = endpointFailures.filter(x => x.method?.find(y => y.id == method));

    for (const failure of postfailures) {
      const match = new RegExp(failure.endpoint).test(endpoint);
      if (match) {
        const chance = Math.floor(Math.random() * 10) + 1;

        if (chance <= failure.failureRate) {
          return of(null).pipe(
            mergeMap(() => {
              if (failure.errorCode == 400) {
                return throwError(
                  () =>
                    new HttpErrorResponse({
                      status: 400,
                      statusText: 'Bad Input',
                      error: {
                        error: {
                          details: [
                            {
                              code: 'Bad Input',
                              message: 'Validation Error - Test'
                            }
                          ],
                          message: 'The request was invalid. Please see the details.'
                        }
                      }
                    })
                );
              } else if (failure.errorCode == 409) {
                return throwError(() => new HttpErrorResponse({ status: 409, statusText: 'Conflict - Test' }));
              } else if (failure.errorCode == 404) {
                return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'Not Found - Test' }));
              } else if (failure.errorCode == 403) {
                return throwError(() => new HttpErrorResponse({ status: 403, statusText: 'Forbidden - Test' }));
              } else if (failure.errorCode == 401) {
                return throwError(() => new HttpErrorResponse({ status: 403, statusText: 'Not Authorized - Test' }));
              }
              // Must be 500 then...
              return throwError(() => new HttpErrorResponse({ status: 500, statusText: 'Internal Server Error - Test' }));
            })
          );
        }
      }
    }
    return null;
  }
}
