

<!-- List/Table settings -->
<sl-ui-kit-safari-accordion   name="List Settings">
  <div id="twisty_0-content" >
    <div class="row my-2">
      <div class="form-group col-md-6" >
        <label for="">Max In Memory</label>
        <div class="input-group">
          <input  id='settings-override-max-in-mem' (input)="setMaxListCount($event.target['value'])"  [value]="uiSettings.list.maxCountInMemory" type="text" class="form-control" >

        </div><!-- END: input-group -->
      </div>
      <div class="form-group col-md-6" >
        <label for="">Items Per Page</label>
        <div class="input-group">
          <input  id='settings-override-items-per-page' (input)="setItemsPerPage($event.target['value'])"  [value]="uiSettings.list.pageSize" type="text" class="form-control" >

        </div><!-- END: input-group -->
      </div>

    </div>
    <!-- localStorage.getItem('safari-native-pdf-override') != null; -->
  </div>
</sl-ui-kit-safari-accordion>
<sl-ui-kit-safari-accordion   name="File Settings">
  <div id="twisty_1-content" class="collapse show" data-parent="#twisty_1-link">
    <div class="row my-2">
      <div class="form-group col-md-6" >
        <label for="">Preview Dialog Timeout</label>
        <div class="input-group">
          <input  id='settings-override-preview-dialog-timeout' (input)="setPreviewDialogTimeout($event.target['value'])"  [value]="uiSettings.file.previewDialogTimeLimitInSeconds" type="text" class="form-control" >

        </div><!-- END: input-group -->
      </div>
    </div>
  </div>
</sl-ui-kit-safari-accordion>
<!-- Other toggleable settings -->
<sl-ui-kit-safari-accordion   name="Feature Flags">
  <div id="twisty_2-content" class="collapse show" data-parent="#twisty_2-link">
    <div class="row my-2">
      @for (featureFlag of uiSettingsOverrides.allFeatureFlags; track $index) {
        <div class="form-group col-md-3" >
          <label>{{ featureFlag.name }}</label>
        </div>
        <div class="form-group col-md-3">
          <label class="s_switch mx-3">
            <input type="checkbox"  [(ngModel)]="featureFlag.value">
            <div class="s_slider round">
              <span class="on">YES</span><span class="off">NO</span>
            </div>
          </label>
        </div>
        <div class="col-md-6"></div>
      }

    </div>

  </div>
</sl-ui-kit-safari-accordion>
@if(!isHosted) {
  <sl-ui-kit-actions-bar>

    <a id="settings-override-save"  class="btn btn-primary" role="button" (click)="save()" >Save Overrides</a>
    <a id="settings-override-reset" class="btn btn-primary mx-3" role="button" (click)="reset()" >Clear Overrides</a>
  </sl-ui-kit-actions-bar>
}
