import { Store } from '@ngrx/store';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AppUiReduxObject, SafariHotkey } from '../state/actions/ui-actions';

export class HotKeyRegistrationServiceCommon {
  static isUiBlocked() {
    let uiBlocked = false;
    const blockUi = document.getElementsByTagName('block-ui-content');
    if (blockUi?.item(0)?.children?.length) {
      uiBlocked = blockUi.item(0).children[0].classList?.contains('active');
    }
    return uiBlocked;
  }
  static registerHotkeys(hotkeysService: HotkeysService, appUiReduxObject: AppUiReduxObject, store: Store<any>) {
    hotkeysService.add(
      new Hotkey(
        'alt+s',
        (): boolean => {
          if (!HotKeyRegistrationServiceCommon.isUiBlocked()) {
            // Note: unlike cancel we don't check for any special indicators. Every base page has save() method
            // that it will call when it receives this but the pages that don't save anything just have this as empty method
            // so no need to do anything special
            store.dispatch(appUiReduxObject.default.actions.toggleHotkey({ payload: SafariHotkey.Save }));
          }
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
    hotkeysService.add(
      new Hotkey(
        'alt+w',
        (): boolean => {
          // Responding to SafariHotkey.CLose is hardwired in the base page by calling goBack(). But unlike save() that method
          // is always calling goBack() (the idea being that if cancel doesn't exist on the page there is simply no way of calling it)
          // However now that we have this global caller we need to check if the page has cancel button before we broadcast this.
          const cancelButtonIndicator = document.getElementById('safari-cancel-button-indicator');
          if (cancelButtonIndicator) {
            if (!HotKeyRegistrationServiceCommon.isUiBlocked()) {
              store.dispatch(appUiReduxObject.default.actions.toggleHotkey({ payload: SafariHotkey.Close }));
            }
          }

          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
    hotkeysService.add(
      new Hotkey('alt+f5', (): boolean => {
        localStorage.clear();
        alert('Local Storage cleared');
        return false; // Prevent bubbling
      })
    );
  }
}
