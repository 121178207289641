import { HttpErrorResponse } from '@angular/common/http';
import { Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, Auth0ClientOptions, AuthorizationParams, createAuth0Client, IdToken } from '@auth0/auth0-spa-js';
import { select, Store } from '@ngrx/store';
import { AuthReduxObject, SafariInjector, UserCompany } from '@safarilaw-webapp/shared/common-objects-models';
import { AppDialogUiReduxObject } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ApplicationInsightsService, LoggerService } from '@safarilaw-webapp/shared/logging';

import { SafariPollingService, seqJoin } from '@safarilaw-webapp/shared/utils';
import { jwtDecode } from 'jwt-decode';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, from, interval, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, share, shareReplay, take, tap } from 'rxjs/operators';
import { AutologoutDialogComponent } from '../../components/autologout-dialog/autologout-dialog.component';

export enum AuthClaim {
  CompanyId = 'https://claims.safarilaw.com/companyid',
  UserId = 'https://claims.safarilaw.com/userid',
  Email = 'email',
  ConnectionName = 'https://claims.safarilaw.com/auth0connection',
  EmailVerified = 'email_verified',
  Name = 'name',
  Nickname = 'nickname',
  Picture = 'picture'
}

export enum AuthFailedReason {
  Unknown,
  Deactivated,
  NoAccessToRequestedCompany,
  NoAccessToAnyCompany,
  InvalidState
}

export enum LogoutReason {
  Manual = '1',
  Inactivity = '2'
}

export interface LogoutParams {
  redirectPath?: string;
  reason?: LogoutReason | string;
}

/* DUPE: Safari_User_SelectedCompanyId */
export const STORAGE_AUTH_COMPANYID_KEY = 'Safari_User_SelectedCompanyId';
export const STORAGE_AUTH_TOKEN_EXPIRATION_KEY = 'Safari_Auth_TokenExpiration';
export const STORAGE_AUTH_INVALID_STATE_RETRY_COUNT = 'Safari_Auth_InvalidStateRetryCount';
export const STORAGE_AUTH_LAST_ACTIVITY_TIMESTAMP = 'Safari_Auth_LastActivityTimestamp';
// When this value is set, it indicates that at least one of the tabs has been logged out due to inactivity.
// All other listeners should then immediately log out.
// We need this extra indicator because navigation end updates last activity time, and
// logging out is necessarily a navigation event.
export const STORAGE_AUTH_LOGOUT_REASON = 'Safari_Auth_LogoutReason';

/**
 * Base for auth service.
 * DO NOT forget to provide it in the app
 */
export abstract class AuthService extends SafariInjector {
  private _toastrMessage: ActiveToast<any> = null;
  private _toastrConfig = {
    positionClass: 'toast-center-center',
    easeTime: 0,
    disableTimeOut: true,
    tapToDismiss: false,
    closeButton: false,
    toastClass: 'toast-primary'
  } as IndividualConfig;

  /* This could be a string (a token) or a boolean (false, indicating no valid auth session); We have to type it as any
     This particular subject is used SPECIFICALLY by isAuthenticated observable. isAuthenticated will listen to emissions
     from this observable to properly update itself and notify other upstream listeners about current authentication status
  */
  protected _accessTokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected _loginPendingintervalSub: Subscription = null;
  protected _loginPendingTimerSub: Subscription = null;
  protected _accessToken: string;
  protected _idClaims: IdToken;
  protected _companyIdClaim: string;

  protected _ngZone: NgZone;
  protected _appConfig: AppConfigurationService;
  protected _store: Store<any>;
  protected _router: Router;
  protected _authRO: AuthReduxObject;
  private _pollingService: SafariPollingService;
  protected _injector: Injector;

  protected _auth0ConnectionClaim: string;
  public get auth0ConnectionClaim(): string {
    return this._auth0ConnectionClaim;
  }
  protected _auth0ConnectionClaimSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public get companyIdClaim(): string {
    return this._companyIdClaim;
  }
  protected _companyIdClaimSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected _userIdClaim: string;
  public get userIdClaim(): string {
    return this._userIdClaim;
  }
  protected _userIdClaimSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected _userEmailClaim: string;
  public get userEmailClaim(): string {
    return this._userEmailClaim;
  }
  protected _userEmailClaimSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected _currentCompanyId: string | null;
  get currentCompanyId(): string | null {
    return this._currentCompanyId;
  }
  set currentCompanyId(value: string | null) {
    this._currentCompanyId = value;
    if (value != null) {
      localStorage.setItem(STORAGE_AUTH_COMPANYID_KEY, value);
    } else {
      localStorage.removeItem(STORAGE_AUTH_COMPANYID_KEY);
    }
  }
  protected _currentUserCompanies$: Observable<UserCompany[]>;
  protected _currentUserCompanies: UserCompany[] = null;
  public get currentUserCompanies(): ReadonlyArray<UserCompany> {
    return this._currentUserCompanies;
  }

  get invalidStateRetryCount(): number {
    return +(localStorage.getItem(STORAGE_AUTH_INVALID_STATE_RETRY_COUNT) ?? 0);
  }
  set invalidStateRetryCount(value: number | null) {
    if (value != null) {
      localStorage.setItem(STORAGE_AUTH_INVALID_STATE_RETRY_COUNT, value.toString());
    } else {
      localStorage.removeItem(STORAGE_AUTH_INVALID_STATE_RETRY_COUNT);
    }
  }

  protected _tokenRefreshed$: Observable<any>;

  public initializationComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected _isLoggingOut = false;
  protected _authClient$: Observable<Auth0Client>;
  protected _isAuthenticated$: Observable<boolean>;
  protected _isAuthenticated = false;
  protected _loggerService: LoggerService;
  protected _applicationInsights: ApplicationInsightsService;
  protected _emitDefaultSubjectValues() {
    this._accessTokenSubject$.next(null);
  }
  protected get webAppRoot(): string {
    return `https://${window.location.host}/`;
  }

  protected get authClient$(): Observable<Auth0Client> {
    if (!this._authClient$) {
      this._configureAuth0();
    }
    return this._authClient$;
  }
  protected _resetLoginPending() {
    if (this._loginPendingTimerSub != null) {
      this._loginPendingTimerSub.unsubscribe();
      this._loginPendingTimerSub = null;
    }
    if (this._loginPendingintervalSub != null) {
      this._loginPendingintervalSub.unsubscribe();
      this._loginPendingintervalSub = null;
    }
    const toastrService = this._injector.get(ToastrService);
    if (this._toastrMessage) {
      toastrService.clear(this._toastrMessage.toastId);
    }
  }
  get applicationInsights() {
    if (this._applicationInsights == null) {
      this._applicationInsights = this._injector.get(ApplicationInsightsService);
    }
    return this._applicationInsights;
  }
  private _configureAuth0(config?: Auth0ClientOptions) {
    if (config == null) {
      config = this._getAuth0DefaultConfig();
    }
    this._authClient$ = from(createAuth0Client({ ...config })).pipe(
      shareReplay(1), // Every subscription receives the same shared value
      catchError((err: HttpErrorResponse) => {
        const router = this._injector.get(Router);
        this._resetLoginPending();
        this._emitDefaultSubjectValues();
        setTimeout(() => {
          void router.navigateByUrl('login-error');
        });
        return throwError(() => ({
          ...err,
          silent: true
        }));
      })
    );

    /* Configure other related fields as observables from the client */
    /* Whenever subscribed, attempt to get a token and feed it into _accessToken$. */
    this._tokenRefreshed$ = this._authClient$.pipe(
      concatMap(o => {
        this._startLoginTimer();

        // This will simulate results from forkJoin but will execute in sequence
        return seqJoin([from(o.getTokenSilently({ authorizationParams: { companyId: this.currentCompanyId ?? '', ...this.getAuthorizationParamsOverrides(true) } })), from(o.getIdTokenClaims())]).pipe(
          tap(() => {
            this._resetLoginPending();
          }),
          catchError((err: HttpErrorResponse) => {
            this._resetLoginPending();
            return throwError(() => err);
          })
        );
      }),
      /* It doesn't really matter what goes wrong; Just treat the accessToken as invalid and let them login */
      catchError((error: Error) => {
        this._resetLoginPending();
        this.logAuth0Error(error);
        return of([null, undefined]);
      }),
      tap(([token, claims]) => {
        this._resetLoginPending();
        this._accessTokenSubject$.next(token as string);
        this._idClaims = claims as IdToken;

        this.handleTokenAndClaims(token, claims);
      }),
      map(([token]) => token as string),
      tap(token => {
        if (token) {
          this.invalidStateRetryCount = null;

          localStorage.removeItem(STORAGE_AUTH_LOGOUT_REASON);
          this.scheduleTokenRefresh(token);

          this.initializationComplete.next(true);
        }
      }),
      /* This ensures that no matter how many request a token, only one will be processed at a time */
      share(),
      /* So subscribers do not need to clean up afterward */
      // TODO: Question - should this really be up to the subscriber ?
      take(1)
    );

    this._currentUserCompanies$.subscribe(companies => {
      this._currentUserCompanies = companies;
    });

    this._store
      .pipe(
        select(this._authRO.default.selectors.getLastActivityTimestamp),
        filter(o => !!o)
      )
      .subscribe(value => {
        this.scheduleInactivityTimeout();
      });
  }

  scheduleInactivityTimeout() {
    this._ngZone.runOutsideAngular(() => {
      clearTimeout(this.#inactivityTimeoutTimer);

      if (this._appConfig.auth.inactivityTimeout && this._appConfig.auth.inactivityWarning) {
        const lastActivityTimestamp = +localStorage.getItem(STORAGE_AUTH_LAST_ACTIVITY_TIMESTAMP) || Date.now();
        const shouldLogoutReason = localStorage.getItem(STORAGE_AUTH_LOGOUT_REASON);
        const timeoutTimestamp = lastActivityTimestamp + 1000 * 60 * this._appConfig.auth.inactivityTimeout;
        const warningTimestamp = timeoutTimestamp - 1000 * 60 * this._appConfig.auth.inactivityWarning;

        if (this.isAuthenticated() && shouldLogoutReason) {
          this.logout({ reason: shouldLogoutReason });
        } else if (this.isAuthenticated() && Date.now() >= warningTimestamp) {
          this.showAutoLogoutDialog();
        } else {
          // Schedule the next check. Check at least once every 5 seconds in case another tab has logged out.
          this.#inactivityTimeoutTimer = setTimeout(
            () => {
              this.scheduleInactivityTimeout();
            },
            Math.min(warningTimestamp - Date.now(), 5000)
          ) as unknown as number;
        }
      }
    });
  }

  #inactivityTimeoutTimer: number; // Represents the result of setTimeout. This timer will be replaced frequently.
  #tokenRefreshTimer: number; // Represents the result of setTimeout. This timer will be replaced frequently.

  private scheduleTokenRefresh(token: string) {
    clearTimeout(this.#tokenRefreshTimer);

    const tokenExpirationTimestamp = this.getTokenExpiration(token);
    if (tokenExpirationTimestamp) {
      localStorage.setItem(STORAGE_AUTH_TOKEN_EXPIRATION_KEY, tokenExpirationTimestamp.toString());
    }

    /* Try to auto-refresh 30s before token expires. Wait at least 30s though.
     * There's a 5 minute grace period from the API rejecting a token regardless, so it doesn't have to be perfectly precise. */
    const refreshDelayMs = Math.max(tokenExpirationTimestamp - Date.now() - 30000, 30000);
    this.#tokenRefreshTimer = this._pollingService.setTimeout(() => {
      this.requestNewTokenForErrorHandler$()
        .pipe(take(1))
        .subscribe(newToken => {
          // Maybe we eventually add a nice popup and allow them to log back in without interrupting their work if the token refresh fails,
          // but for now we can just ignore it.
        });
    }, refreshDelayMs) as unknown as number;
  }

  showAutoLogoutDialog() {
    const appDialogUiObject = this._injector.get(AppDialogUiReduxObject);
    const store = this._injector.get(Store);
    store.dispatch(
      appDialogUiObject.default.actions.openModalDialog({
        payload: {
          component: AutologoutDialogComponent.ClassId,
          parentData: {
            timeout: this._appConfig.auth.inactivityWarning
          }
        }
      })
    );
  }

  protected abstract handleTokenAndClaims(token, claims);

  private _getAuth0DefaultConfig(): Auth0ClientOptions {
    return {
      domain: `${this._appConfig.auth0.authorityRoot}`,
      clientId: `${this._appConfig.auth0.clientId}`,
      ...(this._appConfig.auth0.useLocalStorageCache ? { cacheLocation: 'localstorage' } : {}),
      useRefreshTokens: true,
      authorizationParams: {
        audience: 'https://api.safarilaw.com/',
        // eslint-disable-next-line @typescript-eslint/naming-convention -- AUTH parameter
        redirect_uri: `${this.webAppRoot}auth-callback`
      }
    };
  }

  /** Override in derived classes to provide app specific values and overrides */
  protected getAuthorizationParamsOverrides(isSilentRenewal: boolean = false): Partial<AuthorizationParams> {
    return {};
  }

  initializeObservables() {
    this._currentUserCompanies$ = this._store.select(this._authRO.default.selectors.getCurrentUserCompanies);
    this.currentCompanyId = localStorage.getItem(STORAGE_AUTH_COMPANYID_KEY);
    /* Allow either method of authentication to indicate "isAuthenticated" */
    this._isAuthenticated$ = this._accessTokenSubject$.pipe(
      distinctUntilChanged(),
      map(accessToken => accessToken !== null)
    );

    this._isAuthenticated$.subscribe(isAuthenticated => {
      this._isAuthenticated = isAuthenticated;
    });
    this._accessTokenSubject$.subscribe((token: string) => {
      this._accessToken = token;
    });
    this._auth0ConnectionClaimSubject$.subscribe((auth0Connection: string) => {
      this._auth0ConnectionClaim = auth0Connection;
    });
    this._companyIdClaimSubject$.subscribe((companyId: string) => {
      this._companyIdClaim = companyId;
    });
    this._userIdClaimSubject$.subscribe((userIdClaim: string) => {
      this._userIdClaim = userIdClaim;
    });
    this._userEmailClaimSubject$.subscribe((userEmailClaim: string) => {
      this._userEmailClaim = userEmailClaim;
    });
  }
  constructor() {
    super();
    this._appConfig = this.inject(AppConfigurationService);
    this._store = this.inject(Store);
    this._router = this.inject(Router);
    this._authRO = this.inject(AuthReduxObject);
    this._pollingService = this.inject(SafariPollingService);
    this._loggerService = this.inject(LoggerService);
    this._injector = this.inject(Injector);
    this._ngZone = this.inject(NgZone);

    this.initializeObservables();
  }
  logAuth0Error(error: Error) {
    // Don't spam with login required errors
    if (error.message && error.message.toLowerCase() == 'login required') {
      return;
    }

    /* These are common but expected errors from Auth0. They don't need to be logged. */
    if (
      (error['error'] === 'missing_refresh_token' && (error['error_description'] as string)?.includes('Missing Refresh Token')) ||
      (error['error'] === 'invalid_grant' && (error['error_description'] as string)?.includes('Unknown or invalid refresh token'))
    ) {
      return;
    }

    this._loggerService.LogError(error, window.location.href);
  }
  public login(redirectTargetRoute?: string, prefilledEmail?: string, connection?: string, startTimer = false): BehaviorSubject<any> {
    // this._emitDefaultSubjectValues();
    if (startTimer) {
      this._startLoginTimer();
    }

    const errorObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    this.authClient$.subscribe(authClient => {
      authClient
        ?.loginWithRedirect({
          appState: {
            redirectTargetRoute
          },
          authorizationParams: {
            // eslint-disable-next-line @typescript-eslint/naming-convention -- AUTH parameter
            login_hint: prefilledEmail || '',
            connection: connection ?? '',
            companyId: this.currentCompanyId ?? '',
            ...this.getAuthorizationParamsOverrides()
          }
        })
        .catch(error => {
          errorObservable.next(error);
          this.logAuth0Error(error as Error);
        });
    });

    return errorObservable;
  }

  public selectCompany(companyId: string, redirectTargetRoute: string) {
    this.currentCompanyId = companyId;
    this.login(redirectTargetRoute, null, /* TODO - SSO companies with multi company access will need to propogate the connection paramter to avoid the Auth0 universal login page */ null);
  }

  public postAuthNavigate(router: Router, redirectTargetRoute: string) {
    void router.navigateByUrl(redirectTargetRoute);
  }
  /*
    This is the main entry point from callback component - it begins the authentication process
  */
  public handleAuthentication(): void {
    let redirectTargetRoute: string; // Path to redirect to after login processsed
    let authFailedReason: AuthFailedReason = AuthFailedReason.Unknown;
    const authComplete$ = this.authClient$.pipe(
      // The first thing we need to do AFTER the callback is to call authclient to handle it. That will give us
      // redirect target info. Also just send the client down to lower-stream pipes as part of the result
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
      tap(authResult => {
        const appState: { redirectTargetRoute: string } = authResult.appState as { redirectTargetRoute: string };
        // Get and set target redirect route from callback results
        redirectTargetRoute = appState && appState.redirectTargetRoute ? appState.redirectTargetRoute : '/';
      }),
      concatMap(() =>
        // Redirect callback complete; get user and login status

        // This part here calls one-time subscribe on tokenRefreshed observable
        // This observable is the core engine of getting info about the user and claims
        // and it also triggers accessTokenSubject$ and indirectly isAuthenticated$ observable
        // both of which will be needed to continue the process
        this._tokenRefreshed$.pipe(map(token => token != null && token != false))
      ),
      catchError((err: Error) => {
        // eslint-disable-next-line no-console -- We want to keep this console.log
        console.warn(`Authentication failed (${err.message}).`);

        if (err.message.includes('Invalid state')) {
          authFailedReason = AuthFailedReason.InvalidState;
          err.message.replace('Invalid state', `Invalid state${this.invalidStateRetryCount ? ' retry ' + this.invalidStateRetryCount : ''}`);
        } else if (err.message.includes('user is blocked')) {
          authFailedReason = AuthFailedReason.Deactivated;
        } else if (err.message.includes('ERR1002')) {
          authFailedReason = AuthFailedReason.NoAccessToRequestedCompany;
        } else if (err.message.includes('ERR1001')) {
          authFailedReason = AuthFailedReason.NoAccessToAnyCompany;
        }

        this.logAuth0Error(err);

        return of(false);
      }),
      take(1)
    );
    // Subscribe to authentication completion observable.
    authComplete$.subscribe(isAuthenticated => {
      this._resetLoginPending();
      /* Only navigate ONCE after authentication changes to true */
      if (isAuthenticated) {
        /* Do not redirect until we successfully load the user and company. A company configured with SSO may result in
         * a 404 error for user and redirect to the SSO init page. If we redirect first, other background requests may be initiated
         * at the redirect target that may interfere with the SSO init process even after redirecting to the SSO init page. */
        /** Let's keep this secondary check for now, just in case
         */
        // setTimeout(() => {
        //   if (this._swUpdate.isEnabled) {
        //     this._swUpdate.checkForUpdate().catch(err => {
        //       this._loggerService.LogError(err, window.location.href);
        //     });
        //   }
        //   // We can't call logSwNotAvailable from here due to a circular dependency
        // }, 1000);

        this.postAuthNavigate(this._router, redirectTargetRoute);
      } else {
        this._store.dispatch(this._authRO.default.actions.loginFailed(null));
        // Remove tokens and expiry time and bail out
        this._resetLoginPending();
        this._emitDefaultSubjectValues();
        if (authFailedReason === AuthFailedReason.InvalidState && this.invalidStateRetryCount < 3) {
          this.invalidStateRetryCount++;
          /* Not exactly sure what would happen if this was an SSO attempt that required a connection name to complete, but I believe that
           * Invalid state typically will result in valid Auth0 session, and so sending the user back to the login page shouldn't even require
           * entering a username, SSO or not. I think that this is an exceptional case, and the worst thing that happens might be that the user may
           * get speed bumped and asked for their username. */
          this.login(redirectTargetRoute);
        } else {
          this.invalidStateRetryCount = null;
          void this._router.navigate(['/login-error'], { queryParams: { authFailedReason } });
        }
      }
    });
  }

  public getAccessToken(): string {
    return this._accessToken;
  }

  public getIdClaim(claim: AuthClaim): string {
    return this._idClaims[claim] as string;
  }
  public requestNewTokenForErrorHandler$() {
    // This is somewhat similar to tokenRefreshedObservable but
    // the crucial difference is that it will not try to trigger accessTokenSubject
    // Trying to trigger that from here (in the middle of exception handling)
    // will cause strange things to happen because it will implictly trigger isAuthenticated
    // observable which in turn will trigger all sorts of things up in the webapp which
    // in turn will make everyone sad... :(
    return this.authClient$.pipe(
      concatMap(o =>
        // This will simulate results from forkJoin but will execute in order
        seqJoin([from(o.getTokenSilently({ authorizationParams: { companyId: this.currentCompanyId ?? '', ...this.getAuthorizationParamsOverrides(true) } })), from(o.getIdTokenClaims())])
      ),
      catchError((error: Error) => {
        this.logAuth0Error(error);
        this._store.dispatch(
          this._authRO.default.actions.refreshTokenForErrorHandlerFailed({
            payload: {}
          })
        );

        return of([null, undefined]);
      }),
      tap(([newToken, newClaims]) => {
        this._accessToken = newToken as string;
        // For some reason claims come back as undefined from this call. But the exact same concat statement above
        // returns them just fine during the initial login. Not sure why that is but we don't need to update claims during
        // some random API call that 401ed due to brief inactivity.
        this._idClaims = newClaims == null ? this._idClaims : (newClaims as IdToken);

        if (newToken) {
          localStorage.removeItem(STORAGE_AUTH_LOGOUT_REASON);
          this.scheduleTokenRefresh(newToken as string);
        }

        this._store.dispatch(
          this._authRO.default.actions.refreshTokenForErrorHandlerSuccess({
            payload: {
              claims: newClaims as IdToken,
              token: newToken as string
            }
          })
        );
      }),
      map(([token]) => token as string)
    );
  }
  protected _startLoginTimer() {
    if (this._loginPendingTimerSub != null) {
      return;
    }
    this._loginPendingTimerSub = timer(2500).subscribe(() => {
      const toastrService = this._injector.get(ToastrService);
      let message = 'Please wait';
      this._toastrMessage = toastrService.show(message, 'Driving to the Safari', this._toastrConfig);
      this._loginPendingintervalSub = interval(500).subscribe(() => {
        const componentInstance = toastrService.toasts.find(o => o.toastId == this._toastrMessage.toastId).toastRef.componentInstance as { message: string };
        message += '.';
        if (message.endsWith('....')) {
          message = 'Please wait';
        }
        componentInstance.message = message;
      });
    });
  }

  /*
    This function is called ONLY when the app is first initialized
  */
  public renewTokensAsPromise() {
    // eslint-disable-next-line no-console -- We want to keep this console.log
    console.log('Init: renewTokensAsPromise');
    return new Promise<boolean>(resolve => {
      if (this._appConfig.auth0.useLocalStorageCache) {
        this._startLoginTimer();
        /* Simply touching the client property initializes it.
         * We no longer get a token immediately; Instead we wait for auth-callback (explicit login attempt) */

        // NEW CODE
        // Leaving old code for now (below) but it has an issue
        // tokenrefreshed is defined as this.authClient.pipe(xyz), which means
        // it already contains the calls that are defined in authClient (createAuth0 etc)
        // But the old code was first subscribing to authClient (first call to createAuth0),
        // THEN it was subscribing to tokenRefreshed which in turn contains authClient.pipe in the beginning
        // and therefore restarts the same subscription that just finished.
        // This is easily demonstrated by adding tap to authClient observable and re-enabling old code.
        // Seems like that was a bug but not sure so leaving the old code behind.
        if (this.authClient$ == null) {
          this._configureAuth0();
        }
        this._tokenRefreshed$.pipe(take(1)).subscribe(() => resolve(true));

        // OLD CODE
        // this.authClient$.pipe(take(1)).subscribe(authClient => {
        //   /* Attempt to refresh token from existing session */

        //   // TODO: Question - what happens if token is null/false in this case ?
        //   this._tokenRefreshed$.subscribe(token => resolve(true));
        // });
      } else {
        resolve(true);
      }
    });
  }

  public get isLoggingOut() {
    return this._isLoggingOut;
  }
  public logout(options: LogoutParams = {}): void {
    options = { ...{ redirectPath: 'logout', reason: LogoutReason.Manual }, ...options };

    this._isLoggingOut = true;
    this.currentCompanyId = null;
    localStorage.removeItem(STORAGE_AUTH_COMPANYID_KEY);
    localStorage.removeItem(STORAGE_AUTH_TOKEN_EXPIRATION_KEY);
    // Signal to other tabs that they should also log out
    localStorage.setItem(STORAGE_AUTH_LOGOUT_REASON, options.reason.toString());

    clearTimeout(this.#inactivityTimeoutTimer);
    clearTimeout(this.#tokenRefreshTimer);

    if (this.isAuthenticated()) {
      const logoutPage = this.webAppRoot + options.redirectPath;
      this.authClient$.pipe(take(1)).subscribe(authClient => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- AUTH function returns void | Promise<void> and confuses the linter
        authClient.logout({ clientId: this._appConfig.auth0.clientId, logoutParams: { returnTo: logoutPage } });
      });
    } else {
      // Remove tokens and expiry time; Don't do this above, because it affects the "isAuthenticated" check.
      this._emitDefaultSubjectValues();
      const router = this._injector.get(Router);
      void router.navigateByUrl(options.redirectPath);
    }
  }

  public isAuthenticated(): boolean {
    return !!this._isAuthenticated;
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$;
  }

  /**
   *
   * @param token The token to decode. If not provided, the current token will be used
   * @returns Expiration as an epoch timestamp
   */
  public getTokenExpiration(token: string = null): number {
    if (!token) {
      token = this._accessToken;
    }
    if (typeof token !== 'string') {
      return null;
    }
    const { exp } = jwtDecode(token);
    return exp * 1000;
  }

  public isTokenExpired(): boolean {
    const exp = this.getTokenExpiration();
    return !exp || exp < Date.now();
  }

  public isTokenExpiring(): boolean {
    const exp = this.getTokenExpiration();
    return !exp || exp - 30000 < Date.now();
  }

  public get companyIdClaim$(): Observable<string> {
    return this._companyIdClaimSubject$;
  }
  public get userEmailClaim$(): Observable<string> {
    return this._userEmailClaimSubject$;
  }

  public get hasMultipleCompanies(): boolean {
    return this._currentUserCompanies?.length > 1;
  }
}
