import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const environmentImports = [
  StoreDevtoolsModule.instrument({
    name: '[SafariLaw] - LPMS',
    trace: true,
    maxAge: 1000,
    logOnly: false,
    autoPause: true,
    // Filter actions you don't care about
    actionsBlocklist: [
      /[Navigation]/.source,
      /[Lpms] Clear/.source,
      /[Auth]/.source,
      /[CoManage] Clear/.source,
      /[UiKit][Form]/.source,
      /[UiKit][Accordion]/.source,
      /[UiKit][Table]/.source,
      /[Dropdown]: Clear/.source,
      '[Shared] Form Update',
      '[Auth] Update Last Activity Timestamp',
      /Get My User/.source,
      /Get My Company/.source,
      /Allow Navigation/.source,
      /Set Page Title/.source,
      /Object Saving/.source
    ],
    connectInZone: true
  })
];
