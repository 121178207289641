/* eslint-disable @typescript-eslint/no-unsafe-return -- framework level, ok with any*/
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- framework level, ok with any */
/* eslint-disable @typescript-eslint/no-unsafe-call -- framework level, ok with any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- framework level, ok with any */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { InjectOptions, Injectable, ProviderToken, inject } from '@angular/core';
import { ApiCallContext, CrudServiceParams, HttpMethod, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import * as deepDiff from 'deep-diff';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { FileDownloadService } from '../../../file/services/file-download.service';
import { ApiDataAdapter } from '../../adapter/adapter';
import { CrudTestErrorService } from './crud-test-error.service';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- TS type filter statement that confuses lint
type FilterNotStartingWith<Set, Needle extends string> = Set extends `${Needle}${infer __X}` ? never : Set;

export class Collection<T> {
  items: T[];
  totalCount: number;
  id?: SafariObjectId;
  __etag?: string;
}

/*
  This should really be just going to final endpoints and not try to do safari-style mappings, adapters, etc. 
  However at this time there is CrudGenericService which is calling some of these endpoints and 
  I am not sure if it depends on Safari-mappings or not. But once we get rid of that service (used only
  by file transfer) we can move concepts like ID mapping up to safari-crud.service.ts and leave this
  just as a raw "call endpoint, return raw response" service. It should not be exported  outside of this
  project either
*/
@Injectable()
export abstract class CrudServiceBase<T extends SafariObject> {
  protected _httpClient: HttpClient;
  protected _fileDownloadService: FileDownloadService;
  protected readonly _appConfig: AppConfigurationService;

  static getHeadersAsString(headers: HttpHeaders) {
    // I don't think this can happen, but...
    if (headers == null) {
      return 'N/A';
    }
    const headersArray = [];
    headers.keys().forEach(key => {
      if (key.toLowerCase() != 'authorization') {
        headersArray.push({ key, value: headers.get(key) });
      }
    });
    return JSON.stringify(headersArray);
  }

  protected _getHttpClient() {
    return this._httpClient;
  }

  constructor() {
    this._httpClient = this.inject(HttpClient);
    this._appConfig = this.inject(AppConfigurationService);
    this._fileDownloadService = this.inject(FileDownloadService);
  }

  inject<T1>(token: ProviderToken<T1>, options: InjectOptions = { optional: false }): T1 {
    // eslint-disable-next-line no-restricted-syntax -- framework level class.
    return inject(token, options);
  }

  protected getHeaders(additionalHeaders = {}) {
    return new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
      ...{ 'Content-Type': 'application/json', Accept: 'application/json' },
      ...additionalHeaders
    });
  }
  protected mapCollection(collectionToMap: Collection<T>, adapter: ApiDataAdapter<T>, __etag: string, __parentIds: string[] = [], context: ApiCallContext = null) {
    let collection: Collection<T> = null;
    if (!adapter) {
      collection = { items: collectionToMap.items, totalCount: +collectionToMap.totalCount };
    } else {
      if (context == null) {
        context = {};
      }
      context['__parentIds'] = __parentIds;
      collection = { items: collectionToMap.items.map(x => adapter.fromListModel(x, context)) as unknown as T[], totalCount: +collectionToMap.totalCount };
    }
    if (__parentIds != null && __parentIds.length > 0) {
      if (adapter && adapter['__useSafariObjectId']) {
        collection.items = collection.items.map(o => ({ ...o, id: SafariObject.id(__parentIds, o.id), __etag }));
      } else {
        collection.items = collection.items.map(o => ({ ...o, __parentIds, __etag }));
      }
    }

    return collection;
  }
  // Is this really needed ? Maybe only for adapterless objects but I don't think we have those anymore
  // Once we confirm  we should be able to remove this without problems as adapters don't assign
  // these hidden props in the first place
  private _removeHiddenProps(o: Record<string, unknown>): FilterNotStartingWith<keyof T, '__'> {
    const result = { ...o };
    const objHiddenProps = new SafariObject();
    delete objHiddenProps.id;
    for (const prop in objHiddenProps) {
      if (Object.prototype.hasOwnProperty.call(objHiddenProps, prop)) {
        if (Object.prototype.hasOwnProperty.call(result, prop)) {
          delete result[prop];
        }
      }
    }
    return result as unknown as FilterNotStartingWith<keyof T, '__'>;
  }
  private _convertRawObjectToSafariObject(id: SafariObjectId, o: HttpResponse<T>, adapter: ApiDataAdapter<T>, context: ApiCallContext = null) {
    let obj = cloneDeep(o.body);
    if (obj) {
      const parentIds = this._getParentIds(id, true).parentIds;

      const useSafariObjectId = adapter != null && (adapter['__useSafariObjectId'] as boolean);

      if (adapter) {
        // If we have an adapter and we're using new (compound) ID then we'll
        // recreate the ID by extracting parentIDs from the original ID in the request
        // and we'll concat to API's reponse. This will always result in correct
        // ID passed to the adapter
        if (useSafariObjectId && parentIds?.length > 0) {
          obj.id = SafariObject.id(parentIds, obj.id as string);
        }
        obj = adapter.fromGetModel(obj, context) as unknown as T;
      }
      // The following are non-object properties and always need to come after any potential adapter changes.
      // These are not customizable and adapter has no control over them
      if (!useSafariObjectId && parentIds != null && parentIds.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated -- needed for backward compatibility for now
        obj.__parentIds = [...parentIds];
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- cloneDeep
      obj.__base = cloneDeep(o.body);
      obj.__etag = o.headers.get('ETag');
      return obj;
    }
    return {};
  }

  protected _post(endpoint: string, objectToUpdate: T, adapter: ApiDataAdapter<T>, context: ApiCallContext = null): Observable<any> {
    const testError = CrudTestErrorService.getTestError<T>(endpoint, HttpMethod.POST);
    if (testError) {
      return testError;
    }

    // We dont' know exactly what the type will look like after the adapter is done with it,
    // so we'll cast to a generic object Record<string, unknown>
    const objectForApi = (adapter ? adapter.toCreateModel(objectToUpdate, context) : objectToUpdate) as Record<string, unknown>;

    return this._getHttpClient()
      .post<T>(endpoint, this._removeHiddenProps(objectForApi), {
        headers: this.getHeaders(),
        observe: 'response'
      })
      .pipe(map(o => this._convertRawObjectToSafariObject(objectToUpdate.id, o, adapter, context)));
  }
  _put(objectToUpdate: T, endpoint: string = null, adapter: ApiDataAdapter<T>, context: ApiCallContext = null): Observable<T> {
    const testError = CrudTestErrorService.getTestError<T>(endpoint, HttpMethod.PUT);
    if (testError) {
      return testError;
    }

    // strip out web-specific hidden props. Won't hurt to send it as API would ignore it but no need to
    // transfer extra payload

    // We dont' know exactly what the type will look like after the adapter is done with it,
    // so we'll cast to a generic object Record<string, unknown>
    const objectForApi = (adapter ? adapter.toUpdateModel(objectToUpdate, context) : objectToUpdate) as Record<string, unknown>;
    return this._getHttpClient()
      .put<T>(endpoint, this._removeHiddenProps(objectForApi), {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
        headers: objectToUpdate.__etag ? this.getHeaders({ 'If-Match': objectToUpdate.__etag }) : this.getHeaders(),
        observe: 'response'
      })
      .pipe(
        map(
          // In general all API methods so far return 204 no content (so, no body) but we'll still expand the body.
          o => ({ __etag: o.headers.get('ETag'), ...o.body })
        )
      );
  }
  _patch(objectToUpdate: Partial<T>, originalObject: Partial<T>, endpoint: string = null, adapter: ApiDataAdapter<T>, context: ApiCallContext = null): Observable<T> {
    return this._getHttpClient()
      .patch<T>(endpoint, adapter.toUpdatePartialModel(objectToUpdate, originalObject, context).patchCommands, {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
        headers: objectToUpdate.__etag ? this.getHeaders({ 'If-Match': objectToUpdate.__etag }) : this.getHeaders(),
        observe: 'response'
      })
      .pipe(map(o => o.body));
  }
  protected _retrieveRawBlobResponse(endpoint: string) {
    return this._httpClient.get(endpoint, { observe: 'response', responseType: 'blob' });
  }
  protected _retrieveParsedBlobResponse(endpoint: string) {
    return this._fileDownloadService.fileBodyFromFileObservable(this._retrieveRawBlobResponse(endpoint));
  }

  private _getParentIds(allIds: SafariObjectId, includesObjectId: boolean): { objectId: string; parentIds: string[] } {
    if (!SafariObject.idIsCompound(allIds)) {
      return {
        objectId: allIds as string,
        parentIds: []
      };
    }
    const parentIds = [...SafariObject.idToArray(allIds)];
    let lastElementId: string = null;
    if (includesObjectId) {
      lastElementId = parentIds.pop();
    }
    return {
      objectId: lastElementId,
      parentIds
    };
  }

  protected _retrieveJsonResponse(
    endpoint: string, // 'arraybuffer' | 'blob' | 'json' | 'text';
    payload: T,
    adapter: ApiDataAdapter<T>,
    id: SafariObjectId = null,
    context: ApiCallContext = null
  ) {
    const testError = CrudTestErrorService.getTestError<T>(endpoint, HttpMethod.GET);
    if (testError) {
      return testError;
    }

    return this._getHttpClient()
      .get<T>(endpoint, {
        // Send If-None-Match if the original request also contains payload
        // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
        headers: payload != null ? this.getHeaders({ 'If-None-Match': payload.__etag }) : this.getHeaders(),

        observe: 'response',
        reportProgress: false,
        responseType: 'json'
      })
      .pipe(
        map(o => this._convertRawObjectToSafariObject(id, o, adapter, context)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 304) {
            // If we get 304 then return original payload that we sent in this request.
            // Note: We'll get 304 only if we send if-none-match header, which in
            // turn we'll only get if we send payload with the GET request
            // ALSO!... This has to be deep cloned, because the original payload will come back from
            // redux store and will be flagged as readonly by ngrx. In theory this should be OK
            // but some upstream objects migth still do somehtign to the payload like inforequestservice for example
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- cloneDeep
            return of(cloneDeep(payload));
          }
          return throwError(() => error);
        })
      );
  }
  protected _delete(endpoint: string, body: any) {
    const testError = CrudTestErrorService.getTestError<T>(endpoint, HttpMethod.DELETE);
    if (testError) {
      return testError;
    }
    return this._getHttpClient().delete<T>(endpoint, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- body can be anything for delete method
      body: body || undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
      headers: this.getHeaders({ 'If-Match': '*' })
    });
  }
  protected _retrieveAll(endpoint: string, adapter: ApiDataAdapter<T>, parentIds: string[] = [], context: ApiCallContext = null): Observable<Collection<T>> {
    const testError = CrudTestErrorService.getTestError<Collection<T>>(endpoint, HttpMethod.GET);
    if (testError) {
      return testError;
    }

    return this._getHttpClient()
      .get<any>(endpoint, { headers: this.getHeaders(), observe: 'response', reportProgress: false, responseType: 'json' })
      .pipe(
        map((o: HttpResponse<Collection<T>>) => {
          if (o.body == null) {
            const headers = CrudServiceBase.getHeadersAsString(o.headers);
            throw new Error(`BODY NULL ON SUCCESSFUL RESPONSE! URL: ${o.url || ''}, STATUS: ${o.status || ''}, STATUSTEXT: ${o.statusText || ''}, HEADERS: ${JSON.stringify(headers)}`);
          }
          const etag = o.headers.get('ETag');
          const list = this.mapCollection(o.body, adapter, etag, parentIds, context);
          list.__etag = etag;
          return list;
        })
      );
  }
  protected _updateAll(
    endpoint: string,
    originalList: T[],
    updatedList: T[],
    adapter: ApiDataAdapter<T>,
    parentIds: any[] = [],
    context: ApiCallContext = null,
    params: CrudServiceParams = null,
    idProp: string = 'id'
  ): Observable<Collection<T>> {
    const patchObject = [];
    let index = 0;
    const originalObjectIdsCovered = [];
    for (const originalObject of originalList) {
      originalObjectIdsCovered.push(originalObject[idProp]);
      const objectInUpdatedList = updatedList.find(o => o[idProp] == originalObject[idProp]);
      if (objectInUpdatedList == null) {
        // this is deletion
        patchObject.push({
          op: 'remove',
          path: '/items/' + index.toString()
        });
      } else {
        const diffs = deepDiff.diff(objectInUpdatedList, originalObject);
        if (diffs != null) {
          patchObject.push({
            op: 'replace',
            path: '/items/' + index.toString(),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- cloneDeep
            value: adapter ? adapter.toUpdateListModel(objectInUpdatedList) : cloneDeep(objectInUpdatedList)
          });
        }
      }
      // else do nothing - no changes or deletions happened
      index++;
    }
    // By now we went through all the objects in the original list. But there could also be objects in the updated
    // list that don't exist in the original (add). So let's go throught that
    const newObjects = updatedList.filter(o => originalObjectIdsCovered.includes(o[idProp]) === false);
    for (const newObject of newObjects) {
      patchObject.push({
        op: 'add',
        path: '/items/-',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- cloneDeep
        value: adapter ? adapter.toUpdateListModel(newObject) : cloneDeep(newObject)
      });
    }
    if (patchObject.length > 0) {
      return (
        this._getHttpClient()
          .patch<any>(endpoint, patchObject, { headers: this.getHeaders(), observe: 'response', reportProgress: false, responseType: 'json' })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- framework - we don't know what it might be
          .pipe(mergeMap(() => this._retrieveAll(endpoint, adapter, parentIds, context)))
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- framework - we don't know what it might be
    return this._retrieveAll(endpoint, adapter, parentIds, context);
  }
  protected getQueryStringFromParams(params: CrudServiceParams) {
    let queryString = '';
    if (params) {
      if (params.additionalFilters && params.additionalFilters.size > 0) {
        queryString =
          '&' +
          Array.from(params.additionalFilters.keys())

            .map(key => {
              const value = params.additionalFilters.get(key);
              if (Array.isArray(value)) {
                let valueToReturn = '';
                for (const arrayValue of value) {
                  valueToReturn += key + '=' + arrayValue + '&';
                }
                return valueToReturn.slice(0, -1);
              } else {
                return key + '=' + value;
              }
            })
            .join('&');
        if (queryString === '&') {
          queryString = '';
        }
      }

      if (params.orderBy) {
        queryString += '&orderBy=' + params.orderBy;
      }
      if (params.skip) {
        queryString += '&skip=' + params.skip.toString();
      }
      if (params.top != null && !isNaN(params.top) && params.top > 0) {
        queryString += '&top=' + params.top.toString();
      } else {
        // Always ask for every record if top is invalid. This will be the case with inmemory tables
        // and the API will only return 500 items if we don't set an explicit top
        queryString += `&top=${this._appConfig.uiSettings.list.maxCountInMemory}`;
      }
    } else {
      // Always ask for every record if there are no params. This will be the case with inmemory tables
      // and the API will only return 500 items if we don't set an explicit top
      queryString += `&top=${this._appConfig.uiSettings.list.maxCountInMemory}`;
    }
    return queryString !== '' ? queryString.substring(1) : queryString;
  }
}
