<div [id]="wrapperId" [class]="'s_accordion-wrap ' + wrapperClasses">
  @if(hasHeader) {
    <div [id]="headerId" [class]="headerClasses">
      <button [id]="id + '-toggle'" class="s_accordion-link link-button" [class.collapsed]="isCollapsed" (click)="toggle()">{{ name }}</button>
      @if(button1) {
        <button (click)="onbutton1Click()" class="extra-button-1" [class]="button1Class" [style]="button1Style">{{ button1Label }}</button>
        @if(button1AdditionalText) {
          <div [class]="button1AdditionalTextClass">{{ button1AdditionalText }}</div>
        }
        @if(button1AdditionalHtml) {
          <div [class]="button1AdditionalHtmlClass" [innerHTML]="button1AdditionalHtml | safeHtml"></div>
        }        
      }
    </div>
  }

  <div [id]="contentId" class="s_sec-wrap" [collapse]="isCollapsed" [isAnimated]="true">
    <div class="s_sec-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
 