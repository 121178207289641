import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter, map, Subscription, take } from 'rxjs';
import { AppDialogUiReduxObject } from '../../../state/actions/dialog-actions';
import { ConfirmationDialogButton } from '../../models/confirmation-dialog-payload';

@Directive()
export class DialogBaseComponent implements OnInit, OnDestroy {
  protected _closeDialogRequestedBy: ConfirmationDialogButton = null;

  id: string;

  noUiBlock: boolean;
  data: any;
  dialogData: any;

  dialogCloseSubsciption: Subscription;
  constructor(
    protected _appDialogUiReduxObject: AppDialogUiReduxObject,
    protected _modalService: BsModalService,
    protected _store: Store<any>,
    protected _actions: Actions,
    protected _modalRef: BsModalRef = null
  ) {}

  // Child components already call super.ngOnInit() so keeping this here in case we add something
  // in the future.
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method -- leaving it here for now
  ngOnInit() {
    this.#listenForCloseDialogRequest();
  }

  #listenForCloseDialogRequest() {
    this._actions
      .pipe(
        ofType(this._appDialogUiReduxObject.default.actions.closeModalDialog),
        filter(o => SafariObject.idEqual(o.payload.id, this.id)),
        map(o => o.payload),
        take(1)
      )
      .subscribe(() => {
        this.closeModal();
      });
  }
  closeModal(dialogButton: ConfirmationDialogButton = ConfirmationDialogButton.Auto) {
    this._closeDialogRequestedBy = dialogButton;
    this._modalService.setDismissReason(this.id);

    this._modalRef.hide();
  }

  ngOnDestroy() {
    if (this.dialogCloseSubsciption) {
      this.dialogCloseSubsciption.unsubscribe();
    }
    this._store.dispatch(
      this._appDialogUiReduxObject.default.actions.modalDialogDismissed({
        payload: { buttonType: this._closeDialogRequestedBy, noUiBlock: !!this.noUiBlock, id: this.id, parentData: this.data, dialogData: this.dialogData }
      })
    );
  }
}
