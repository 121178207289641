import { Injectable } from '@angular/core';
import { CoManageDataAccessObject } from '@safarilaw-webapp/feature/co-manage/data-access';
import { UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { HTTP_STATUS_CODE_API_NOTFOUND, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ActionSilenceErrorMode, SelectorState } from '@safarilaw-webapp/shared/redux';
import { Observable, map, merge, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LpmsUserVerificationService extends UserVerificationService {
  /**
   *
   * @param CoManageObject WATCH IT here ! This will import the whole module
   * and UserVerificationService is used during app bootstrapping. So this has to be lean.
   *
   * For now it's fine for CoManageDataAccess as it is a fairly small module.
   * But you wouldn't want to import something like LpmsDataAccessObject since that would
   * significantly boost boostrapping module size.
   *
   * Also - if CoManageDataAccess becomes too big in the future we'll probaly have to split out
   * this functionality into another DataAccessModule called CoManageMyDataAccess or something like that
   * and then this would refer to that instead (and CoManageDataAccess would roll that in behind the scenes)
   */
  CoManageObject: CoManageDataAccessObject;
  constructor() {
    super();
    this.CoManageObject = this.inject(CoManageDataAccessObject);
  }
  override checkForUser(): Observable<boolean> {
    this.loadObject(this.CoManageObject.MyUser, SafariObject.NOID, {
      options: {
        silenceErrors: {
          mode: ActionSilenceErrorMode.InList,
          errors: [HTTP_STATUS_CODE_API_NOTFOUND]
        }
      }
    });

    return merge(
      this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID, SelectorState.Current),
      this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID, SelectorState.FailedToLoad)
    ).pipe(
      map(o => {
        // Note - no need to check if the error is NOT_FOUND. THat's the only one we
        // let through so if we get an error here it just HAS TO BE 404
        if (o.error) {
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
  override checkForCompanies(): Observable<boolean> {
    return this.getCompanies().pipe(
      map(o => o.length != 0),
      take(1)
    );
  }
  override getCompanies(): Observable<any[]> {
    this.loadObjectList(this.CoManageObject.MyCompanies, null, {
      options: {
        silenceErrors: {
          mode: ActionSilenceErrorMode.InList,
          errors: [HTTP_STATUS_CODE_API_NOTFOUND]
        }
      }
    });

    return merge(
      this.observeObjectList$(this.CoManageObject.MyCompanies, null, SelectorState.Current),
      this.observeObjectList$(this.CoManageObject.MyCompanies, null, SelectorState.FailedToLoad)
    ).pipe(
      map(o => {
        // Note - no need to check if the error is NOT_FOUND. THat's the only one we
        // let through so if we get an error here it just HAS TO BE 404
        if (o.error) {
          return [];
        }
        return o.items;
      })
    );
  }
}
