<div class="test" style="margin-bottom: 0px;">
  <div class="modal-header"  style="justify-content: unset !important;" >
    <button (click)="changeTab(Tabs.FeatureFlagsAndSettings)" type="button" class="btn" [class]="getTabClass(Tabs.FeatureFlagsAndSettings)">Feature Flags/Settings</button>
    <button (click)="changeTab(Tabs.Errors)" type="button" class="btn" [class]="getTabClass(Tabs.Errors)">Errors</button>
  </div>
  <div class="modal-body" style="max-height: 800px;overflow: scroll;"  >
    <sl-dev-settings-override [isHosted]="true"  [hidden]="currentTab != Tabs.FeatureFlagsAndSettings"></sl-dev-settings-override>
    <sl-dev-error [isHosted]="true" [hidden]="currentTab != Tabs.Errors"></sl-dev-error>
  </div>
  <div class="modal-footer justify-content-center">
    
    <div style="margin-left: auto;">
      <button style="margin-left:140px" id="confirmation-dialog-ok-button" [disabled]="!hasChanges" type="button" class="btn-primary btn s_btn-confirmation-ok mr-2"   (click)="closeModal(ConfirmationDialogButton.Ok)">
        <div [innerHTML]="okBtnName ? okBtnName : 'OK'"></div>
        @if (hasOkSubtext()) {
          <div class="s_subtext">{{okBtnSubtext}}</div>
        }
      </button>
      
      <button id="confirmation-dialog-cancel-button"   type="button" class="btn btn-secondary s_btn-confirmation-cancel mr-2"  (click)="closeModal(ConfirmationDialogButton.Cancel)">
        <div [innerHTML]=" 'CANCEL'"></div>
       
      </button>
    </div>


    <button style="margin-left: auto;" id="confirmation-dialog-cancel-button"   type="button" class="btn btn-danger s_btn-confirmation-cancel mr-2 float-right"  (click)="reset()">
      <div [innerHTML]=" 'RESET TO DEFAULTS'"></div>
     
    </button>

  </div>
</div>
 