/* eslint-disable no-restricted-syntax -- This is a framework level file so it's ok */
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, inject, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Action, ActionCreator, NotAllowedCheck, select, Store } from '@ngrx/store';

import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap';
import { PermissionsService } from '@safarilaw-webapp/shared/auth';
import { AppDialogUiReduxObject, ConfirmationDialogButton, ConfirmationDialogButtonProps } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService, AppConfigurationUiSettings, AppConfigurationUrls, FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { ReduxWrapperService, SafariReduxPageUiObject, UiActionSelectorPair } from '@safarilaw-webapp/shared/redux';
import { RouterNavigationInfo } from '@safarilaw-webapp/shared/routing-utility';

import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { safariInjectorMixin } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariPollingService } from '@safarilaw-webapp/shared/utils';
import { IUiManager, UiManagerService } from '../../../shared/services/ui-manager/ui-manager.service';
import { SafariUiAccordionReduxObject, SafariUiComponentReduxObject } from '../../../state/actions/layout-actions';

export class PageUiService extends SafariReduxPageUiObject<any> {
  get default() {
    return null;
  }
}
export class PageErrorContext {
  id: string;
  context: any;
  children?: PageErrorContext[];
}
/**
 * This is the base for both dumb and smart components.
 * It provides a very limited set of functionality/properties which is shared
 * by both smart and dumb components
 */
@Directive()
class SafariBaseComponent<PageServiceType extends PageUiService = PageUiService> {
  private _messages: PageUiService;

  // eslint-disable-next-line @typescript-eslint/naming-convention -- we want this weird name here
  private ___int_rxw: ReduxWrapperService;

  // This property is available in both Dumb and Smart components and each one of them
  // will inject it in the appropriate manner
  featureFlagService: FeatureFlagsService;
  safariPollingService: SafariPollingService;
  appConfigurationService: AppConfigurationService;
  protected _componentId: string = null;
  /*
    componentId        - Must be unique. Used to identify the form and send/receive form messages via store. Usually it will be assigned
                    in the constuctor of the child
  */
  get componentId(): string {
    return this._componentId;
  }
  @Input()
  set componentId(value: string) {
    this._componentId = value;
  }
  // Angular doesn't allow simply enumerating every child or enumerating every child by some base
  // class so we have to use this directive. There may be another way that might be more automatable (via safari-schematics)
  // but for now form and other compoenents that are hosted by this container need to add checkChanges template reference
  @ViewChildren('checkChanges') _checkChangesComponents: any;

  get messages(): PageServiceType {
    return this._messages as PageServiceType;
  }

  getDropdownStateLocation(state: string, dropdownId: number | string) {
    return state + '.' + dropdownId.toString();
  }
  // DO NOT add any params to this constructor. Dumb component inherits from this
  constructor() {}

  /**
   * Prefer this method to sendMessage. But to use it you have to create actionSelector in the state first (look at the existing examples using this)
   * @param actionAndSelector
   * @param payload
   * @param async
   */
  sendMessage<P extends object, AT extends string, ActionType extends ActionCreator<AT, (props: P & NotAllowedCheck<P>) => P & Action<AT>>>(
    actionAndSelector: UiActionSelectorPair<ActionType, any>,
    payload: Omit<ReturnType<typeof actionAndSelector.action>, 'type'> = null,
    async: boolean = false
  ) {
    this.___int_rxw.dispatchGenericAction(actionAndSelector.action(payload as any), async);
  }
  /*
    hasUnsavedChanges - Goes through all components that wish to have their changes checked and calls hasUnsavedChanges()
                        Usually called by EditGuard before leaving the page
  */
  hasUnsavedChanges() {
    if (this._checkChangesComponents) {
      for (const component of this._checkChangesComponents) {
        if (component && component.hasUnsavedChanges && component.hasUnsavedChanges() === true) {
          return true;
        }
      }
    }
    return false;
  }
  _resizeWindow() {
    // We should call this when we are showing something that contains ngx datatable. It seems to fix some weird
    // datatable column behaviors by forcing it to refresh itself
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }
}

/**
    Slightly modified version of DumbComponent from 
    https://generic-ui.com/blog/enterprise-approach-to-the-smart-and-dumb-components-pattern-in-angular
 */
@Directive()
export class SafariDumbComponent<PageServiceType extends PageUiService = PageUiService> extends SafariBaseComponent<PageServiceType> {
  private _name: string;

  // The second allowed service should only wrap around page-level page redux object actions
  // The child component can then ask the main page to do something or inform it of something.
  // This service should not expose selectors - the main page can listen to selectors by directly injecting the page
  // redux object into itself. As long as that rule is followed this service is no more
  // than simplification of @Output() directive for sending events to the parent (which is a legitimate dumb component action).
  // The only exception is that it doesn't require redeclaration of @Output()s in the hierarchy tree and it can also provide
  // reactive features on the main page.

  // NOTE: Currently the only service that fits this requirement is LpmsMatterListPageUiService.
  // Other page UI services currently expose selectors and actionlisteners which would allow for dumb component to bypass properties.
  // Some even expose functions that dispatch directly to redux data objects, which is even worse.
  // Hoping that we'll clean those up over the next several iterations, time permitting.

  // AVOID ADDING OTHER SERVICE INPUTS TO CHILDREN OF THIS COMPONENT
  // THE TWO ABOVE SHOULD COVER MOST CASES AND EVERYTHING ELSE SHOULD BE JUST PLAIN INPUT VALUES
  //
  // However, if there's absolutely some service that needs to be added as an input it should be just
  // a helper service and never something that can call APIs, bypass the main component, etc.

  constructor() {
    super();
    this._name = this.constructor.name;
    if (this.constructorHasParameters || arguments.length !== 0) {
      this.throwError('it should not inject services');
    }
    this.inject();
  }
  /**
  * Note that this function has the same name as the function in SafariSmartComponent. 
  * This allow us to use the same syntax to mock in the UTs.
  * However, its' purpose is different - unlike the one in the smart compoenent, which 
  * allows injection of any arbitrary object - this one only injects objects which we allow. 
  * 
  * In other words - this doesn't provide any sort of "loophole" around Dumb component's ban
  * on arbitrary service injection. 
  * 
  * To mock: jest.spyOn(SafariSmartComponent.prototype, 'inject').mockImplementation()
  * 
  * OR
  * 
  * jest.spyOn(SafariSmartComponent.prototype, 'inject').mockImplementation((token: ProviderToken<any>) => {
      if (token == FeatureFlagsService) {
        return TestBed.inject(token) OR return {} OR something else
      }
      return null;
    });
  */

  inject() {
    this.featureFlagService = inject(FeatureFlagsService);
    this.safariPollingService = inject(SafariPollingService);
    this.appConfigurationService = inject(AppConfigurationService);
    this['___int_rxw'] = inject(ReduxWrapperService);
    this['_messages'] = inject(PageUiService, {
      optional: true
    });
  }

  private get constructorHasParameters(): boolean {
    const subClassConstructorAsString = this.constructor.toString();
    const constructorFunctionIndex = subClassConstructorAsString.indexOf('constructor');
    return subClassConstructorAsString.substring(constructorFunctionIndex).split('(')[1][0] !== ')';
  }

  private throwError(reason: string): void {
    throw new Error(`Component "${this._name}" is a DumbComponent, ${reason}.`);
  }
}

@Directive()
/**
 * NOTE: Do not inherit from this unless you are making some new smart(ish) components that need
 * to subscribe to observables, etc.
 *
 * But in general - if you need a page that talks to the API use SafariBasePageComponent (and there should be only one
 * per page). The rest should be mostly dumb components, with a few exceptions on very large pages
 */
export class SafariSmartComponent<PageServiceType extends PageUiService = PageUiService>
  extends safariInjectorMixin(SafariBaseComponent)<PageServiceType>
  implements OnInit, OnDestroy, IUiManager, AfterViewInit
{
  appConfiguration: AppConfigurationService;

  protected _reduxWrapper: ReduxWrapperService;
  protected _uiComponentReduxObject: SafariUiComponentReduxObject;

  private _dialogResponseSubscription: Subscription;

  @ViewChildren('errorContext') _errorContextComponents: any;

  private _subs: Map<string, Subscription> = new Map<string, Subscription>();
  private _dialogIdsToListenTo = new Map<string, { function: (button: ConfirmationDialogButton, parentData: any, dialogData: any) => void; clearAfterNotify: boolean }>();

  protected _logger: LoggerService;

  protected _store: Store<any>;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- "avoid use in children"
  protected ___route: ActivatedRoute;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- "avoid use in children"
  protected ___router: Router;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- "avoid use in children"
  protected ___uiManager: IUiManager;

  protected _appUiReduxObject: AppUiReduxObject;
  protected _appDialogUiReduxObject: AppDialogUiReduxObject;
  protected _cdr: ChangeDetectorRef;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- "avoid use in children"

  // eslint-disable-next-line @typescript-eslint/naming-convention -- "avoid use in children"
  private ___safariAccordionUiReduxObject: SafariUiAccordionReduxObject;
  protected _userPermissionsService: PermissionsService;

  constructor() {
    super();
    this['___int_rxw'] = this.inject(ReduxWrapperService);
    this._reduxWrapper = this['___int_rxw'];
    /** Be mindful of what you put in the constructor. In many of our UTs
     * we are bypassing injections to make them faster and we just new up the component manually.
     * For this reason the constructor should only inject via this.inject and at most set some
     * simple properties.
     *
     * If there is anything that depends on the result of injection it should be checked for NULL,
     * but even better approach might be to put that in onInit if possible.
     *
     * Observable defs should go to onInit
     */
    this.featureFlagService = this.inject(FeatureFlagsService);
    this['_messages'] = this.inject(PageUiService, {
      optional: true
    });
    this.appConfiguration = this.inject(AppConfigurationService);
    this._appUiReduxObject = this.inject(AppUiReduxObject);
    this._appDialogUiReduxObject = this.inject(AppDialogUiReduxObject);
    this._uiComponentReduxObject = this.inject(SafariUiComponentReduxObject);
    this._store = this.inject(Store);
    this.___route = this.inject(ActivatedRoute);
    this._logger = this.inject(LoggerService);
    this.___router = this.inject(Router);
    this.___uiManager = this.inject(UiManagerService);

    this.___safariAccordionUiReduxObject = this.inject(SafariUiAccordionReduxObject);
    this._cdr = this.inject(ChangeDetectorRef);
    this._userPermissionsService = this.inject(PermissionsService);
    // This is needed by the "if-not-readytodisplay directive"
    const element = this.inject(ElementRef);
    if (element) {
      element.nativeElement.__component = this;
    }
  }

  get uiSettings(): AppConfigurationUiSettings {
    return this.appConfiguration?.uiSettings;
  }
  get urls(): AppConfigurationUrls {
    return this.appConfiguration?.urls;
  }

  protected getErrorContext(): PageErrorContext {
    // Make sure to at least implement something returning unique page ID
    // For additional context query whatever page variables and add them to context property,
    // ideally as some Json object
    return {
      id: '',
      context: '',
      children: []
    };
  }
  getFullErrorContext() {
    const mainContext = this.getErrorContext();
    mainContext.children = [];
    if (this._errorContextComponents) {
      for (const component of this._errorContextComponents) {
        if (typeof component.getFullErrorContext == 'function') {
          mainContext.children.push(component.getFullErrorContext());
        }
      }
    }
    return mainContext;
  }

  ngOnInit() {
    this._dialogResponseSubscription = this._store
      .pipe(
        select(this._appDialogUiReduxObject.default.selectors.getDialogResponse),
        filter(o => o != null)
      )
      .subscribe(o => {
        const sub = this._dialogIdsToListenTo.get(o.id);
        if (sub != null) {
          sub.function(o.buttonType, o.parentData, o.dialogData);
          if (sub.clearAfterNotify) {
            this._dialogIdsToListenTo.delete(o.id);
          }
        }
      });
  }
  setReadyToDisplay(value: boolean) {
    // Decided to put the check for same value before dispatching. A hard 404 due to a wrong endpoint in the upload dialog
    // will freeze the browser because it will keep calling repeatedly forever. We should look into this and find the
    // reason but even if we fix that there could be other bugs out there that could call this multiple times
    // and I'd rather not have users' browsers hanging forever.

    // Or do we maybe do this in production and the "no-check" version in development ? Then
    // we wouldn't have worries about users browsers hangup in prod, but in dev we would run into the issue and we could
    // fix the specific problem.
    this._store
      .select(this._uiComponentReduxObject.default.selectors.readyToDisplay(this._componentId))
      .pipe(take(1))
      .subscribe(o => {
        if (o != value) {
          this._store.dispatch(this._uiComponentReduxObject.default.actions.setReadyToDisplay({ payload: { id: this._componentId, readyToDisplay: value } }));
        }
      });
    // Don't bother checking if the existing value is the same. Just send to redux and let redux deal with it (setting same value to same value won't
    // trigger the observable anyway)
    //this.___store.dispatch(this._uiComponentReduxObject.default.actions.setReadyToDisplay({ payload: { id: this._componentId, readyToDisplay: value } }));
  }

  ngOnDestroy(): void {
    if (this._dialogResponseSubscription) {
      this._dialogResponseSubscription.unsubscribe();
      this._dialogResponseSubscription = null;
    }

    this.clearAllCustomSubscriptions();
    if (this.componentId) {
      // Always set readyToDisplay back to false when the component is being destroyed. Otherwise it may be possible that when the component
      // reloads it thinks it's ready due to a previously stored value. That could potentially cause weird flashing or even hard errors. Haven't
      // experienced this in practice but I suppose it's possible.
      this._store.dispatch(this._uiComponentReduxObject.default.actions.setReadyToDisplay({ payload: { id: this._componentId, readyToDisplay: false } }));
    }
  }

  /**
   * Listent to a UI message. It is meant to be used in conjucntion with sendMessage2
   * @param actionSelectorPair
   * @param filterNull
   * @returns
   */
  observeMessage$<SelectorType>(actionSelectorPair: UiActionSelectorPair<any, SelectorType>, filterNull = false): Observable<SelectorType> {
    return this._reduxWrapper.getGenericSelector(actionSelectorPair.selector, filterNull).pipe(
      tap(() =>
        this._reduxWrapper.dispatchGenericAction({
          type: actionSelectorPair.action.type + ' Observed'
        })
      )
    );
  }
  /**
   * Helper function for save methods. It will ignore undefined messages
   * and will only take 1 value after that
   * @param actionSelectorPair
   */
  observeMessageOnce$<SelectorType>(actionSelectorPair: UiActionSelectorPair<any, SelectorType>, filterNull = true) {
    return this.observeMessage$(actionSelectorPair).pipe(
      filter(o => !filterNull || o !== undefined),
      // Now that we passed that return the value that was set and do that only once
      take(1)
    );
  }
  /**
   * This is our standard subscribe function which is used to keep track of subscriptions
   * and unsubscribe when the component is destroyed. It should be used for all long-running
   * observables
   * @param observable
   * @param subscriptionFunction
   * @returns
   */
  subscribe<T>(observable: Observable<T>, subscriptionFunction: (value: T) => void = null): string {
    const guid = uuidv4();
    this._subs.set(guid, observable.subscribe(subscriptionFunction));
    return guid;
  }
  /**
   * This function is meant to be used for throwaway observables that get unsubscribed immediately.
   * Examples include things like deletes, updates, creates,etc. Basically one-off events that
   * execute upon some event (usually user action like click), they do something for that event
   * only and they are done.
   * Note that technically since all of our one-offs start with subscription to of(null), which completes
   * at the end, in most cases it is not even necessary to call this function. But to provide consistency
   * between the calls we should probably wrap those up in this function.
   * @param observable
   * @param subscriptionFunction
   */
  subscribeOnce<T>(observable: Observable<T>, subscriptionFunction: (value: T) => void = null) {
    observable.pipe(take(1)).subscribe(subscriptionFunction);
  }
  unsubscribe(guids: string | string[]) {
    if (!Array.isArray(guids)) {
      guids = [guids];
    }
    for (const guid of guids) {
      const sub = this._subs.get(guid);
      if (sub) {
        sub.unsubscribe();
        this._subs.delete(guid);
      }
    }
  }
  clearAllCustomSubscriptions() {
    if (this._subs) {
      this._subs.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    }
    this._subs = new Map<string, Subscription>();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method -- We want an empty method here. Children are supposed to call lifecycle methods from super and if we ever added something here we want to make sure it gets called.
  ngAfterViewInit(): void {}
  /**
   * @deprecated - Follow use observables as in company-entity-edit or settings-general-edit
   * @param dialogId
   * @param fn
   * @param clearAfterNotify
   */
  setupDialogSubscription(dialogId: string, fn: (button: ConfirmationDialogButton, parentData: any, dialogData: any) => void, clearAfterNotify = true) {
    this._dialogIdsToListenTo.set(dialogId, { function: fn, clearAfterNotify });
  }
  /**
   * @deprecated - Follow use observables as in company-entity-edit or settings-general-edit
   * @param dialogId
   * @param fn
   * @param clearAfterNotify
   */
  dispatchOpenDialog1Btn(id: string, title: string, content: string, okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' }, initState = {}): string {
    return this.___uiManager.dispatchOpenDialog1Btn(id, title, content, okBtnName, initState);
  }
  onPageLeave(nextState: RouterStateSnapshot) {
    // It is important that we call onDestroy here. onPageLeave is our workaround because we overrode default
    // NG strategy and pageLeave tells us that this is an actual page leave, not simply a reload of the component
    // However, pageLeave is called while the component still exists and while it will eventually result in leaving
    // the page and calling onDestroy anyway it's important to do that here so that any component subs get cleaered.
    // If not it's possible that call of "clearState" in pageLeave of safari-base-page-component will trigger
    // an observable in the child page that might be listening on "NULL" state and might try to execute an action
    // while the page is in process of leaving, or even leave the page in a bad state after it leaves it.
    // For example in case of reports pageLeave triggered clearState, which clears the state as it should, but
    // it also triggered a report to be generated as that one was triggering right after the state was cleared (because
    // the component hasn't called ondestroy and the sub was active). Then it was putting the value
    // back in the state, which would result in invalid pagestate the next time page was visited.
    this.ngOnDestroy();
  }
  /**
   * @deprecated - Follow use observables as in company-entity-edit or settings-general-edit
   * @param dialogId
   * @param fn
   * @param clearAfterNotify
   */
  dispatchOpenDialog2Btn(
    id: string,
    title: string,
    content: string,
    okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' },
    cancelBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'CANCEL' },
    initState = {}
  ): string {
    return this.___uiManager.dispatchOpenDialog2Btn(id, title, content, okBtnName, cancelBtnName, initState);
  }
  /**
   * @deprecated - Follow use observables as in company-entity-edit or settings-general-edit
   * @param dialogId
   * @param fn
   * @param clearAfterNotify
   */
  dispatchOpenDialog3Btn(
    id: string,
    title: string,
    content: string,
    okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' },
    cancelBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'CANCEL' },
    auxBtnName: string | ConfirmationDialogButtonProps,
    initState = {}
  ): string {
    return this.___uiManager.dispatchOpenDialog3Btn(id, title, content, okBtnName, cancelBtnName, auxBtnName, initState);
  }

  dispatchGenericError(error: any, source: string, payload: any = null, silent: boolean = false, mustResolve: boolean = false): void {
    this.___uiManager.dispatchGenericError(error, source, payload, silent, mustResolve);
  }
  dispatchNavigate(navInfo: RouterNavigationInfo): void {
    this.___uiManager.dispatchNavigate(navInfo);
  }
  dispatchToggleBlockUi(block: boolean, transparent = false): void {
    this.___uiManager.dispatchToggleBlockUi(block, transparent);
  }

  dispatchAllowNavigation(allow: boolean): void {
    this.___uiManager.dispatchAllowNavigation(allow);
  }

  getGenericReduxAction(action: any): Observable<any> {
    return this.___uiManager.getGenericReduxAction(action);
  }
  expandTwisty(el: Element) {
    if (el.tagName.toUpperCase() == 'SL-UI-KIT-SAFARI-ACCORDION' || el.tagName.toUpperCase() == 'SL-LPMS-SERVING-PARTY-ACCORDION') {
      this._store.dispatch(this.___safariAccordionUiReduxObject.default.actions.accordionExpand({ payload: { id: el.id } }));
    }
  }
  /**
   * Expands all parent twisties. Expands all containing twisties for a given HtmlElement.
   * Used by error framework to show a field that raised a validation error but might be hidden inside collapsed twisties.
   */
  expandAllContainingTwisties(el: Element) {
    while (el) {
      this.expandTwisty(el);
      el = el.parentElement;
    }
    return el;
  }
}
