<aside class="s_act" id="s_act-primary">
    <div class="row no-gutters">
        <div class="col">
            <ng-content>

            </ng-content>
        </div><!-- END: col -->
    </div><!-- END: row -->
</aside>

 