



                <sl-ui-kit-safari-accordion   name="Service Error Simulator">
              
                    <div class="m-2">
                      <div class="row mb-2 p-2" >
                        <div class="col-md-4">
                          Name
                        </div>
                        <div class="col-md-4">
                          Method
                        </div>
                        <div class="col-md-1">
                          Failure Rate
                        </div>
                        <div class="col-md-1">
                          Error Code
                        </div>
                        <div class="col-md-2">
                          Off/Clear
                        </div>
                      </div>
                      @for(endpointFailure of endpointFailures; track $index; let odd = $odd, even = $even) {
                        <div class="mb-2 p-2" [class]="getErrorDivClass(odd)" >
                         
                          <div class="row">
                            <div class="form-group col-md-4">
                              <input type="text" style="width: 100%;" [disabled]="!endpointFailure.canDelete"  [(ngModel)]="endpointFailure.name" />
                            </div>
                            <div class="form-group col-md-4">
                              <ng-multiselect-dropdown
                              [(ngModel)]="endpointFailure.method"
                              placeholder="None Selected"
                              class="s_multiselect-dropdown"
                               [settings]="{
                                
                                  idField: 'id',
                                  textField: 'name',
                                  enableCheckAll: true,
                                  itemsShowLimit: 100,
                                  unSelectAllText: 'Remove All'
      
                               }"
                              [data]="[{
                                id:HttpMethod.POST, name: 'POST'
                               
                              }, {
                                id:HttpMethod.PUT, name: 'PUT',
                               
                              }, {
                                id:HttpMethod.DELETE, name: 'DELETE',
                           
                              }, {
                                id:HttpMethod.GET, name: 'GET'
                              }]"
                             >
                          </ng-multiselect-dropdown>
    
                            </div>
                            <div class="form-group col-md-1">
                              <select [(ngModel)]="endpointFailure.failureRate">
                                @for (errorRate of errorRates; track $index) {
                                  <option [ngValue]="errorRate">{{ (errorRate * 10) + ' %' }} </option>
                                }
                              </select>

                              <!-- <rating [(ngModel)]="endpointFailure.failureRate" [max]="10"></rating> -->
                            </div>
                            <div class="form-group col-md-1">
                              <select [(ngModel)]="endpointFailure.errorCode">
                                @for (statusCode of statusCodes; track statusCode.id) {
                                  <option [ngValue]="statusCode.id">{{ statusCode.name }} </option>
                                }
                              </select>
                            </div>
                            <div class="form-group col-md-2">
                              <button class="btn" [class]="getOffButtonClass($index)" type="button" (click)="turnOffError($index)">O</button>
                              <button [disabled]="!endpointFailure.canDelete" class="btn  btn-primary" type="button" (click)="clearError($index)">X</button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-12">
                              <input [disabled]="!endpointFailure.canDelete" type="text" style="width: 100%;"   [(ngModel)]="endpointFailure.endpoint" />
                            </div>
                            </div>  
                        </div>
     
                      }
                      <div class="row p-3">
                        <button class="btn  btn-primary" type="button" (click)="addError()">ADD</button>
                      </div>
                      @if(!isHosted) {
                        <div class="row">
                          <button [disabled]="!hasChanges" class="btn  btn-primary col-12 mt-2" type="button" (click)="save()">UPDATE GLOBAL ERROR INTERCEPTS</button>
                          <button  class="btn  btn-danger col-12 mt-2" type="button" (click)="reset()">RESET ERROR INTERCEPTS</button>
  
                        </div>
                      }

                     

                    </div>
 
                </sl-ui-kit-safari-accordion>


                <sl-ui-kit-safari-accordion [defaultExpanded]="false"  name="Global Error Simulator">
                  <!-- ACCORDION CONTENT______________________________________________________________________________ -->
                  <div  >
                    @if (!buttonClicked) {
                      <div class="row">
                        <div class="form-group col-md-12" >
                          <hr>
                          </div>
                          <div class="form-group col-md-12" >
                            <label for="">Javascript Error</label>
                            <div class="input-group">
                              <button class="btn btn-primary" type="button" (click)="throwJsError()">Throw</button>
                            </div><!-- END: input-group -->
                          </div>
                          <div class="form-group col-md-12" >
                            <hr>
                            </div>
                            <div class="form-group col-md-12" >
                              <label for="">Unknown Error (0)</label>
                              <div class="input-group">
                                <button class="btn  btn-primary" type="button" (click)="throwHttpError(0)">Throw</button>
                              </div><!-- END: input-group -->
                            </div>
                            <div class="form-group col-md-12" >
                              <hr>
                              </div>
                              <div class="form-group col-md-12" >
                                <label for="">Validation Error (400)</label>
                                <div class="input-group">
                                  <button class="btn  btn-primary" type="button" (click)="throwHttpError(400)">Throw</button>
                                </div><!-- END: input-group -->
                              </div>
                              <div class="form-group col-md-12" >
                                <hr>
                                </div>
                                <div class="form-group col-md-12" >
                                  <label for="">Http Error (504)</label>
                                  <div class="input-group">
                                    <button class="btn  btn-primary" type="button" (click)="throwHttpError(504)">Throw</button>
                                  </div><!-- END: input-group -->
                                </div>
                                <div class="form-group col-md-12" >
                                  <hr>
                                  </div>
                                  <div class="form-group col-md-12" >
                                    <label for="">Http Error (500)</label>
                                    <div class="input-group">
                                      <button class="btn  btn-primary" type="button" (click)="throwHttpError(500)">Throw</button>
                                    </div><!-- END: input-group -->
                                  </div>
                                  <div class="form-group col-md-12" >
                                    <hr>
                                    </div>
                                    <div class="form-group col-md-12" >
                                      <label for="">Http Error (403)</label>
                                      <div class="input-group">
                                        <button class="btn  btn-primary" type="button" (click)="throwHttpError(403)">Throw</button>
                                      </div><!-- END: input-group -->
                                    </div>
                                    <div class="form-group col-md-12" >
                                      <hr>
                                      </div>
                                      <div class="form-group col-md-12" >
                                        <label for="">Http Error (404)</label>
                                        <div class="input-group">
                                          <button class="btn  btn-primary" type="button" (click)="throwHttpError(404)">Throw</button>
                                        </div><!-- END: input-group -->
                                      </div>
                                      <div class="form-group col-md-12" >
                                        <hr>
                                        </div>
                                      </div>
                                    } @else {
                                      Please wait - http calls might retry 3 times before throwing
                                    }
                                  </div>
                                </sl-ui-kit-safari-accordion>