import { HttpErrorResponse } from '@angular/common/http';
import { ApiCallContext, AutoRetrieveOnUpdate, CrudServiceParams, ObjectHistory, SafariObjectId, TransferDialogOptions } from '@safarilaw-webapp/shared/common-objects-models';
// import { Collection } from '@safarilaw-webapp/shared/crud';
import { ReduxError } from '../state/actions/actions';

// TODO: Copy-pasted from crud.service to avoid circular dependency. Need to refactor the project for the final fix
class Collection<T> {
  items: T[];
  totalCount: number;
  id?: SafariObjectId;
  __etag?: string;
}

export enum ActionSilenceErrorMode {
  All,
  InList,
  NotInList
}
/**
 * Note - this doesn't mean that the errors won't be heard by the framework. They
 * will be heard, and they will be logged as well (unless explictly excluded by the logger service).
 * What this means, however, is that they won't be surfaced to the user in their usual manner.
 * So for 400 and 409s that would mean * "don't show the dialog", for others "don't show the error page"
 */
export class ActionSilenceErrorOption {
  /** Silence error mode -
   * All - No errors will be raised
   * InList - Silence only the errors whose status matches the one in errors[] array of these option
   * NotInList - Silence only errors that are not found in errors[] array of these option
   * */
  mode: ActionSilenceErrorMode;
  errors?: number[];
}

export class ActionOptions {
  silenceErrors?: ActionSilenceErrorOption;
  maxValidationErrors?: number;
  transferDialogOptions?: TransferDialogOptions;
}
class CommonActionProps {
  /** Used for correlated effects only */
  correlationId?: string;
  /** Unique ID of this action (not payload!)*/
  actionId?: string;
}
export class ActionInfoBase extends CommonActionProps {
  abort?: string;
  /** I think his needs to be replaced with dedicated abort actions. Maybe ?  */
  /** Loosely typed property bag. Usually used to pass something special to the service that deals with this object */
  context?: ApiCallContext;
  /** Advanced options for this action */
  options?: ActionOptions;
}
/** Base error action - thrown from effects usually  */
export class ActionErrorBase<T = any> extends CommonActionProps {
  /** Error that came back */
  error: HttpErrorResponse;
  /** Original payload */
  payload?: T & { __onErrorMergeWith?: any };

  /** Error options - show to the user, must be resolved, etc  */
  reduxErrorOptions?: ReduxError;

  /** Original payload as sent to the initiating action  */
  originalPayload: T;
  /** Original additional info as sent to the initiating action  */
  context?: any;
  /** Original options as sent to the initiating action  */
  originalOptions?: ActionOptions;
}
export class ActionSuccessBase<T = any> extends CommonActionProps {
  /** Original payload as sent to the initiating action  */
  originalPayload: T;
  /** Original additional info as sent to the initiating action  */
  context?: any;
  /** Original options as sent to the initiating action  */
  originalOptions?: ActionOptions;
}

/**
 * Class that uniquely identifies a list in the state
 */
export class SafariObjectListId {
  /**
   * id of the list - this will only be filled in case of parented lists (eg subpoena/{123}/notes)
   */
  id?: SafariObjectId;
  /**
   * Various filters for the list - this in combination with the ID will be used to find the list in the state
   */
  filter?: CrudServiceParams;
}

export type SwappableActionOptions = ActionOptions & {
  /**
   * Prevent autoswap. If this is set to true then upon loading the object,the object will remain in "loaded" state, and will
   * not automatically be moved into "current" state(the state that is visible to pages)). A separate action will be required
   * to push it into current (moveLoadedToCurrent). This is usually used for back-buffering during polling
   */
  noAutoSwap?: boolean;
  autoRetrieveOnUpdate?: AutoRetrieveOnUpdate;
};
export class SwappableActionInfoBase extends ActionInfoBase {
  options?: SwappableActionOptions;
}
export class SwappableActionErrorBase<T> extends ActionErrorBase<T> {
  originalOptions?: SwappableActionOptions;
}
export class SwappableActionSuccessBase<T> extends ActionSuccessBase<T> {
  originalOptions?: ActionOptions & {
    /**
     * Prevent autoswap. If this is set to true then upon loading the object,the object will remain in "loaded" state, and will
     * not automatically be moved into "current" state(the state that is visible to pages)). A separate action will be required
     * to push it into current (moveLoadedToCurrent). This is usually used for back-buffering during polling
     */
    noAutoSwap?: boolean;
  };
}

export class LoadObjectActionInfo<T> extends SwappableActionInfoBase {
  /**
   *  optional payload representing object that will be automatically returned in case we hit "no change"
      response from the API. Presence of this variable will trigger { 'If-None-Match': payload['__etag'] }
      in the related service\
   */
  original?: T & { actionId?: string };
  payload?: {
    /**
     * Id of the object we want to load
     */
    id: SafariObjectId;
  };
}
export type DeleteMultipleObjectsPayload = { id: SafariObjectId }[];

export class DeleteMultipleObjectsActionInfo extends SwappableActionInfoBase {
  payload?: {
    id: SafariObjectId;
    /**
     * Id of the object we want to load
     */
    deletePayload: DeleteMultipleObjectsPayload;
  };
}
export class DeleteMultipleObjectsActionSuccessInfo<T = { deletePayload: DeleteMultipleObjectsPayload }> extends SwappableActionSuccessBase<DeleteMultipleObjectsPayload> {
  payload?: T;
}
export class DeleteMultipleObjectsActionFailInfo extends SwappableActionErrorBase<{ deletePayload: DeleteMultipleObjectsPayload }> {}

export class LoadObjectActionSuccessInfo<T> extends SwappableActionSuccessBase<{ id: SafariObjectId }> {
  /**
   * Object returned from the API
   */
  payload?: T;
}
export class LoadObjectActionFailInfo extends SwappableActionErrorBase<{ id: SafariObjectId }> {}

export class LoadObjectListActionInfo extends SwappableActionInfoBase {
  /**
   * Information needed to load this list (id, filters, etc)
   */
  payload?: SafariObjectListId;
  options?: ActionOptions & { noAutoSwap?: boolean; countOnly?: boolean };
}
export class LoadObjectListActionSuccessInfo<T> extends SwappableActionSuccessBase<SafariObjectListId> {
  /**
   * List returned from the API
   */
  payload: Collection<T>;
  originalOptions?: ActionOptions & { noAutoSwap?: boolean; countOnly?: boolean };
}
export class LoadObjectListActionFailInfo extends SwappableActionErrorBase<SafariObjectListId> {
  originalOptions?: ActionOptions & { noAutoSwap?: boolean; countOnly?: boolean };
}

export class UpdateObjectListActionInfo<T> extends SwappableActionInfoBase {
  payload: {
    originalList: T[];
    updatedList: T[];
  };
}
export class UpdateObjectListActionFailInfo<T> extends SwappableActionErrorBase<{
  originalList: T[];
  updatedList: T[];
}> {}
export class UpdateObjectListActionSuccessInfo<T> extends SwappableActionSuccessBase<{
  originalList: T[];
  updatedList: T[];
}> {
  /**
   * List returned from the API
   */
  payload: Collection<T>;
}
export enum HttpMethodOverride {
  Post = 1,
  Put = 2
}

export type UpdateOrCreateActionOptions = SwappableActionOptions & {
  /** By default the framework will try to deduce if it should call POST or PUT based on presence of etag
   * That works for most of the objects but there are some helper objects (xtend portal, generate attachments, etc)
   * that this might not do what you want. So use this to tell the framework exactly which HTTP method it should call
   */
  methodOverride?: HttpMethodOverride;
  whitelistedMergePaths?: string[];
  /** By default the framework while saving an object the framework leaves the current version of it in the state
   * This works with general form objects to prevent screen from blinking and is meant to be used with long running
   * observables (our usual approach for normal pages, etc)
   *
   */
  removeCurrent?: boolean;
};
export class UpdateOrCreateActionInfo<T> extends SwappableActionInfoBase {
  options?: UpdateOrCreateActionOptions;
  payload?: T & { actionId?: string };
}
export class UpdateOrCreateActionSuccessInfo<T> extends SwappableActionSuccessBase<T & { actionId?: string }> {
  payload?: T & { actionId?: string; __priorId?: string; __etag?: string };
}
export class UpdateOrCreateActionFailInfo<T> extends SwappableActionErrorBase<T & { actionId?: string }> {
  originalOptions?: ActionOptions & { noAutoSwap?: boolean };
}

export class DeleteObjectActionInfo extends ActionInfoBase {
  payload?: {
    /**
     * Id of the object we want to load
     */
    id: SafariObjectId;
    body?: any;
  };
}
export class DeleteObjectActionSuccessInfo extends ActionSuccessBase<{ id: SafariObjectId }> {
  payload?: {
    /**
     * Id of the object we want to load
     */
    id: SafariObjectId;
  };
}
export class LoadObjectHistoryActionInfo extends SwappableActionInfoBase {
  payload?: {
    /**
     * Id of the object we want to load
     */
    id: SafariObjectId;
  };
}

export class LoadObjectHistoryActionSuccessInfo extends SwappableActionSuccessBase<{ id: SafariObjectId }> {
  payload: ObjectHistory;
}
export class LoadObjectHistoryActionFailInfo extends SwappableActionErrorBase<ObjectHistory> {
  payload: ObjectHistory;
}
export class DeleteObjectActionFailInfo extends ActionErrorBase<{ id: SafariObjectId }> {
  payload?: {
    /**
     * Id of the object we want to load
     */
    id: SafariObjectId;
  };
}

export class ClearObjectErrorActionInfo extends ActionInfoBase {
  /** Id of the object  */
  id: SafariObjectId;
}

export class MoveLoadedToCurrentActionInfo extends ActionInfoBase {
  /** Id of the object  */
  id: SafariObjectId;
}
export class MoveLoadedListToCurrentActionInfo extends ActionInfoBase {
  /** Id of the object  */
  id: SafariObjectId;
}
